// In all the hooks, as of the time of writing this comment, we are selectively passing dependencies so the lint ignores are warranted
import { App as CapacitorApp } from "@capacitor/app";
import { isDefined } from "@clipboard-health/util-ts";
import {
  DeploymentEnvironmentName,
  environmentConfig,
  isProductionNodeEnvironment,
} from "@src/appV2/environment";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { Agent } from "@src/lib/interface";
import { fetchAndSetUpcomingShifts, setChannels, updateChannel } from "@store/chat";
import { ReactElement, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dispatch } from "redux";
import SendBird from "sendbird";
import { SendBirdProvider, useSendbirdStateContext } from "sendbird-uikit";
import { v4 as uuidv4 } from "uuid";

import { useSession } from "../store/helperHooks";

const CHAT_CHANNEL_QUERY_LIMIT = 100;

function useChatEffects(): void {
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;
  const dispatch = useDispatch();
  const ldFlags = useCbhFlags();
  const isSendBirdOptimisationEnabled =
    ldFlags[CbhFeatureFlag.SENDBIRD_CONCURRENT_CONNECTION_OPTIMISATION];

  useEffect(() => {
    if (!sdk || !sdk.GroupChannel) {
      return undefined;
    }

    const uuid = uuidv4();

    const channelHandler = new sdk.ChannelHandler();

    channelHandler.onChannelChanged = (channel: SendBird.GroupChannel) =>
      updateChannel(dispatch, channel);

    channelHandler.onUserReceivedInvitation = (channel: SendBird.GroupChannel) => {
      updateChannel(dispatch, channel);
      fetchAndSetUpcomingShifts(dispatch);
    };

    channelHandler.onMessageReceived = (channel, message) => {
      if (message.isAdminMessage()) {
        fetchAndSetUpcomingShifts(dispatch);
      }
    };

    sdk.addChannelHandler(uuid, channelHandler);

    return () => sdk?.removeChannelHandler?.(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk]);

  useEffect(() => {
    fetchChannels({ sdk, dispatch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk]);

  useEffect(() => {
    // Exit early and avoid adding listeners if the feature flag is turned off
    if (!isSendBirdOptimisationEnabled) {
      return () => null;
    }
    const handleTabChange = ({ isActive }) => {
      // sendbird connections states may be `OPEN` or `CLOSED` or `CONNECTING`
      // we only want to change state if the status is closed
      if (isActive && sdk?.getConnectionState?.() === "CLOSED") {
        sdk?.setForegroundState?.();
        fetchChannels({ sdk, dispatch });
      }

      // We only want to change state if the status is not closed because if connecting
      // we don't want that connection to go through and disconnect immediately
      if (!isActive && sdk?.getConnectionState?.() !== "CLOSED") {
        sdk?.setBackgroundState?.();
      }
    };

    // This is fired when user puts app in the background/foreground
    const subscription = CapacitorApp.addListener("appStateChange", handleTabChange);

    return () => {
      subscription.then((listenerHandle) => listenerHandle.remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk]);
}

interface Props {
  children: ReactNode;
}

/**
 * This component adds effects to SendBird context provider.
 * Consider eliminating this and reorganizing the inner code to run `useSendBirdEffects`
 */
export function ChatContextEffects(props: Props): ReactElement {
  const { children } = props;
  useChatEffects();
  return <>{children}</>;
}

interface ChatProviderProps {
  userId: string;
  agent: Agent;
  children: ReactNode;
}

export function ChatProvider(props: ChatProviderProps): ReactElement {
  const { children, userId, agent } = props;
  const dispatch = useDispatch();
  const { sendBirdAccessToken } = useSession();
  const location = useLocation();

  useEffect(() => {
    fetchAndSetUpcomingShifts(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname === "/home/myShifts") {
      fetchAndSetUpcomingShifts(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <SendBirdProvider
      appId={
        // We only want to set the appId in production or in the chat page, else this increases the usage of sendbird connections
        // Due to this change, the chat counter will not be updated in the app bar when the user is not in the chat page
        (isProductionNodeEnvironment() &&
          environmentConfig.REACT_APP_ENVIRONMENT_NAME === DeploymentEnvironmentName.PRODUCTION) ||
        location.pathname.startsWith("/home/account/chat")
          ? environmentConfig.REACT_APP_SENDBIRD_APP_ID
          : ""
      }
      userId={userId}
      nickname={agent.name}
      accessToken={sendBirdAccessToken}
    >
      {children}
    </SendBirdProvider>
  );
}

export async function fetchChannels(params: {
  searchInput?: string;
  sdk: SendBird.SendBirdInstance;
  dispatch: Dispatch;
}) {
  const { searchInput, sdk, dispatch } = params;
  if (!isDefined(sdk?.GroupChannel)) {
    setChannels(dispatch, []);
    return;
  }

  const query = sdk.GroupChannel.createMyGroupChannelListQuery();
  query.includeEmpty = true;
  query.memberStateFilter = "joined_only";
  query.order = "latest_last_message";
  query.limit = CHAT_CHANNEL_QUERY_LIMIT;

  if (searchInput) {
    query.channelNameContainsFilter = searchInput;
  }

  const channels = await query.next();

  // channel name is `${facilityName} - ${workerName}` and we want to filter for
  // results that match the facilityName only
  const channelsMatchingSearchInput = searchInput
    ? channels.filter((channel) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [facilityName, workerName] = channel.name.split(" - ");
        // Case-insensitive substring search
        return facilityName.toLowerCase().includes(searchInput.toLowerCase());
      })
    : channels;

  setChannels(dispatch, channelsMatchingSearchInput);
}
